define("simtex-myaccount/adapters/application", ["exports", "ember-django-adapter/adapters/drf", "simtex-myaccount/config/environment", "simtex-myaccount/models/custom-inflector-rules", "jquery"], function (_exports, _drf, _environment, _customInflectorRules, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _jquery.default.ajaxSetup({
    xhrFields: {
      withCredentials: true
    }
  });
  var _default = _drf.default.extend({
    host: _environment.default.APP.API_HOST,
    // https://github.com/dustinfarris/ember-django-adapter/blob/master/docs/coalesce-find-requests.md
    // coalesceFindRequests: true,

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, snapshot === null || snapshot === void 0 ? void 0 : snapshot.id, snapshot, requestType, query);
      return url + (url.includes('?') ? '&' : '?') + 'format=json';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      // Check for the callrecording API
      if (snapshot.modelName && snapshot.modelName === "callrecording") {
        var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
        var data = this.serialize(snapshot, {
          includeId: true
        });
        return this.ajax(url, 'PATCH', {
          data: data
        });
      } else if (snapshot.modelName && snapshot.modelName === "musiconholdslot" || "routing") {
        var _snapshot$__attribute;
        var id = String(snapshot === null || snapshot === void 0 ? void 0 : (_snapshot$__attribute = snapshot.__attributes) === null || _snapshot$__attribute === void 0 ? void 0 : _snapshot$__attribute.account_id);
        var _url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
        var _data = this.serialize(snapshot, {
          includeId: true
        });
        return this.ajax(_url, 'PATCH', {
          data: _data
        });
      }

      // Default behavior (PUT for all other APIs)
      return this._super(store, type, snapshot);
    } // handleResponse (status, headers, payload, requestData) {
    //     if (requestData.url.indexOf('/endpoint/') >= 0) {
    //         // debugger;
    //     }
    //     return this._super(...arguments);
    // },
    // dirtyRecordsForAttributeChange (dirtySet, record, attrName, newValue, oldValue) {
    //     debugger;
    //     let meta = record.constructor.metaForProperty(attrName);
    //     if (meta && meta.options.readOnly) { return; }
    //     this._super.apply(this, arguments);
    // },
  });
  _exports.default = _default;
});