define("simtex-myaccount/adapters/application", ["exports", "ember-django-adapter/adapters/drf", "simtex-myaccount/config/environment", "simtex-myaccount/models/custom-inflector-rules", "jquery"], function (_exports, _drf, _environment, _customInflectorRules, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _jquery.default.ajaxSetup({
    xhrFields: {
      withCredentials: true
    }
  });
  var _default = _drf.default.extend({
    host: _environment.default.APP.API_HOST,
    // https://github.com/dustinfarris/ember-django-adapter/blob/master/docs/coalesce-find-requests.md
    // coalesceFindRequests: true,

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);
      return url + (url.indexOf('?') >= 0 ? '&' : '?') + 'format=json';
    }

    // handleResponse (status, headers, payload, requestData) {
    //     if (requestData.url.indexOf('/endpoint/') >= 0) {
    //         // debugger;
    //     }
    //     return this._super(...arguments);
    // },
    // dirtyRecordsForAttributeChange (dirtySet, record, attrName, newValue, oldValue) {
    //     debugger;
    //     let meta = record.constructor.metaForProperty(attrName);
    //     if (meta && meta.options.readOnly) { return; }
    //     this._super.apply(this, arguments);
    // },
  });
  _exports.default = _default;
});