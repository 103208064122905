define("simtex-myaccount/components/numbers/dids/did-subscription-buckets", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    slush_bucket_context: {
      // Reference data
      // Model name for primary bucket
      ref_model_primary: 'account',
      ref_model_primary_type: 'number',
      ref_model_primary_remove_fields: ['did_data', 'channellimit_data', 'dialrestriction_data', 'product_data', 'transport_data', 'voicemail_data', 'callcontrol_data', 'endpoint_status_data', 'endpointaccount_data', 'callaccounting_data', 'didroute_data', 'numberpresentation_data', 'accountcallcontrol_data', 'didroute_choices', "musiconhold_data", "didroute_data"],
      ref_model_primary_ordering: 'internalextension',
      ref_model_primary_optionval: 'accountid',
      // 'id',
      ref_model_primary_optionlabel: '${extension} / ${accountid} - ${label} - ${product_label}',
      ref_model_primary_optionlabeldict: {
        'accountid': 'accountid',
        'label': 'label',
        'product_label': 'product_data_label',
        'extension': 'internalextension'
      },
      ref_model_primary_filter_dict: null,
      // Model name for allocation buckets
      ref_model_allocation: 'did',
      ref_model_allocation_remove_fields: ['didhistoryjson', 'jsondata', 'ipndsite', 'faxrouting'],
      ref_model_allocation_ordering: 'did',
      // Property on the allocation model linking it with the primary model
      ref_model_allocation_primaryrefid: 'account_id',
      // account.id
      ref_model_allocation_primaryrefobj: 'account',
      ref_model_allocation_optionval: 'did',
      // Used in Allocation bucket to describe what the object is allocated to.
      // If null, defaults to primary object label.
      bucket_selected_primary_descriptionref: 'bucket_selected_primary',
      allow_shared_allocations_per_primary: false,
      hide_allocated_in_unallocated_for_superuser: false,
      // If true, shows a modal prompt with OK/Cancel and body text described by modal_prompt_deallocate
      // show_modal_prompt_deallocate: true,

      strings: {
        primary_bucket_title: 'Subscriptions',
        allocated_bucket_title: 'DIDs Mapped',
        unallocated_bucket_title: 'DIDs not mapped to a Subscription',
        allocate_button_text: 'Map Number',
        deallocate_button_text: 'Un-Map',
        // modal_prompt_deallocate: this.simtex.STRINGS.REMOVE_ENDPOINTACCT_WARNING,
        prompt_primary_noresults: 'No Subscriptions are available',
        prompt_allocated_empty_noprimary: 'Select a Subscription to view its mapped DIDs',
        prompt_allocated_empty_noresults: 'No DIDs currently mapped to this Subscription',
        prompt_unallocated_empty: 'No un-mapped DIDs',
        saved_allocations_success: 'Saved DID mapping',
        saved_allocations_failure: 'Error saving DID mapping'
      }
    }
  });
  _exports.default = _default;
});