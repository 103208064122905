define("simtex-myaccount/components/handsets/handset-create-dialog", ["exports", "@ember/component", "simtex-myaccount/mixins/model-saver-common", "ember-data", "@ember/service", "@ember/template", "@ember/object"], function (_exports, _component, _modelSaverCommon, _emberData, _service, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    store: (0, _service.inject)(),
    simtex: (0, _service.inject)('simtex'),
    isLoading: false,
    modalTitle: (0, _object.computed)(function () {
      if (this.modalMode === 'single') {
        return 'Add Single Handset';
      } else if (this.modalMode === 'bulk') {
        return 'Add Bulk Handsets';
      }
    }),
    modalClass: (0, _object.computed)(function () {
      if (this.modalMode === 'single') {
        return 'modal-dialog clr-wizard modal-lg';
      } else if (this.modalMode === 'bulk') {
        return 'modal-dialog clr-wizard modal-xl';
      }
    }),
    bulkdata: null,
    modalShowing: false,
    messageModalShowing: false,
    savingMessage: '',
    resultMessage: '',
    displayImage: 'none',
    endpointmodel: null,
    endpointmodeltypeid: null,
    endpointmodeltype_list: [],
    endpointmodel_list: [],
    endpointdialplan_list: [],
    account_list: [],
    macaddress: null,
    label: null,
    model: null,
    promptOnClose: true,
    modalCancelling: false,
    mac_assign: null,
    elements: (0, _object.computed)(function () {
      if (this.modalMode === 'single') return ['endpointmodeltypeid', 'endpointmodel_id', 'endpointdialplan_id', 'assign_account', 'macaddress'];else if (this.modalMode === 'bulk') return ['endpointmodeltypeid', 'endpointmodel_id', 'endpointdialplan_id', 'bulkdata'];
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.initialiseHandset();
    },
    initialiseHandset: function initialiseHandset() {
      var _this = this;
      var endpointmodel = this.store.createRecord('endpoint', {
        owner: this.simtex.owner_search_term
      });
      this.set('model', endpointmodel);
      this.get_endpointmodeltype_list();
      this.get_account_list();
      this.get('elements').forEach(function (element) {
        if (_this.get("model.".concat(element)) === null || _this.get("model.".concat(element)) === undefined) {
          _this.set("".concat(element, "Invalid"), false);
        }
      });
    },
    get_endpointmodeltype_list: function get_endpointmodeltype_list() {
      var _this2 = this;
      var endpointmodeltype_list = [];
      return _emberData.default.PromiseObject.create({
        promise: this.store.findAll('endpointmodeltype').then(function (results) {
          results.forEach(function (modeltype) {
            endpointmodeltype_list.push({
              display_name: modeltype.name,
              value: modeltype.endpointmodeltypeid
            });
          });
          _this2.set('endpointmodeltype_list', endpointmodeltype_list);
        })
      });
    },
    get_endpointmodel_list: function get_endpointmodel_list() {
      var _this3 = this;
      var endpointmodel_list = [];
      this.store.query('endpointmodel', {
        endpointmodeltype__endpointmodeltypeid: this.endpointmodeltypeid,
        ordering: 'model',
        page_size: 75
      }).then(function (results) {
        results.forEach(function (endpointmodel) {
          endpointmodel_list.push(endpointmodel);
        });
        _this3.set('endpointmodel_list', endpointmodel_list);
      });
    },
    get_endpointdialplan_list: function get_endpointdialplan_list() {
      var _this4 = this;
      var endpointdialplan_list = [];
      this.store.query('endpointdialplan', {
        endpointmodeltype__endpointmodeltypeid: this.endpointmodeltypeid,
        ordering: 'areaname'
      }).then(function (results) {
        results.forEach(function (endpointdialplan) {
          if (_this4.simtex.is_admin || _this4.simtex.is_superuser && endpointdialplan.areaname.includes('TCP')) {
            endpointdialplan_list.push(endpointdialplan);
          }
        });
        _this4.set('endpointdialplan_list', endpointdialplan_list);
      });
    },
    get_account_list: function get_account_list() {
      var _this5 = this;
      var account_list = [];
      // let endpointaccount_promise_list = [];
      this.toggleProperty('isLoading');
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('account', {
          owner_filter_term: this.simtex.owner_search_term,
          productid__producttype__producttypeid: 3,
          page_size: 200,
          remove_fields: ['did_data', 'channellimit_data', 'dialrestriction_data', 'transport_data', 'callcontrol_data', 'advancedforwardcontrol_data', 'endpoint_status_data', 'product_data', 'numberpresentation_data', 'voicemail_data', 'callaccounting_data', 'accountcallcontrol_data', 'baselinekey_data', "musiconhold_data", "didroute_data"]
        }).then(function (accounts) {
          accounts.forEach(function (account) {
            var display_name = '';
            if (account.get('endpointaccount_data').length >= 1 && _this5.simtex.is_admin) {
              display_name = "* ".concat(account.get('internalextension'), " - ").concat(account.get('accountid'));
            } else if (account.get('endpointaccount_data').length === 0) {
              display_name = "".concat(account.get('internalextension'), " - ").concat(account.get('accountid'));
            }
            if (display_name !== '') {
              account_list.push({
                display_name: display_name,
                value: account.get('accountid')
              });
            }
          });
          account_list.sort(function (account_1, account_2) {
            var name_1 = account_1.display_name.toLowerCase();
            var name_2 = account_2.display_name.toLowerCase();
            if (name_1 < name_2)
              //sort string ascending
              return -1;
            if (name_1 > name_2) return 1;
            return 0; //default return value (no sorting)
          });

          if (!_this5.isDestroyed) {
            _this5.set('account_list', account_list);
            _this5.toggleProperty('isLoading');
          }
        })
      });
    },
    processData: function processData() {
      var _this6 = this;
      var result_message = "";
      var bulkdata = this.get('bulkdata');
      var promiseArray = [];
      var assignArray = [];
      if (this.modalMode === 'single') {
        assignArray.push({
          'assign_account': this.get('model.assign_account'),
          'macaddress': this.get('model.macaddress')
        });
        promiseArray.push(_emberData.default.PromiseObject.create({
          promise: this.get('model').save()
        }));
      } else {
        var rows = bulkdata.split(/[\r\n]+/);
        rows.forEach(function (row) {
          var endpoint_data = row.split(',');
          var endpoint_model = _this6.store.createRecord('endpoint', {
            owner: _this6.simtex.owner_search_term
          });
          endpoint_model.set('endpointmodeltypeid', _this6.get('model.endpointmodeltypeid'));
          endpoint_model.set('endpointmodel_id', _this6.get('model.endpointmodel_id'));
          endpoint_model.set('endpointdialplan_id', _this6.get('model.endpointdialplan_id'));
          endpoint_model.set('macaddress', endpoint_data[0]);
          endpoint_model.set('assign_account', endpoint_data[1]);
          endpoint_model.set('label', endpoint_data[2]);
          assignArray.push({
            'assign_account': endpoint_data[1],
            'macaddress': endpoint_data[0]
          });
          promiseArray.push(_emberData.default.PromiseObject.create({
            promise: endpoint_model.save()
          }));
        });
      }
      this.set('mac_assign', assignArray);
      return promiseArray;
    },
    saveEndpoint: function saveEndpoint(endpoint_list) {
      var _this7 = this;
      var result_message = "";
      var self = this;
      Promise.allSettled(endpoint_list).then(function (results) {
        results.forEach(function (result) {
          if (result.status == "rejected") {
            result_message = result_message + "<span style=\"color: #c92100;\">Handset ".concat(result.reason.errors[0].detail, " Creation Failed - ").concat(result.reason.errors[1].detail, "</span><br>");
          } else {
            var endpoint = result.value;
            var assigned_account = '';
            self.get('mac_assign').filter(function (item) {
              return item.macaddress === endpoint.macaddress;
            }).forEach(function (item) {
              assigned_account = item.assign_account;
            });
            var link = "".concat(window.location.protocol, "//").concat(window.location.hostname, ":").concat(window.location.port, "/subscriptions/").concat(endpoint.owner, "/").concat(assigned_account, "/handset/").concat(endpoint.endpointid + 2100000);
            result_message = result_message + "Handset <a href=\"".concat(link, "\"><b>").concat(endpoint.macaddress, "</b></a> created successfully.<br>");
          }
        });
        _this7.set('resultMessage', (0, _template.htmlSafe)(result_message));
      }).catch(function (e) {
        result_message = "<span style=\"color: #c92100;\">Something has gone wrong. Please contact support.</span><br>";
        _this7.set('resultMessage', (0, _template.htmlSafe)(result_message));
      });
    },
    resetModal: function resetModal() {
      this.set('endpointmodeltypeid', null);
      this.set('bulkdata', null);
      this.set('endpointmodel', null);
      this.set('accountid', null);
      this.set('displayImage', 'none');
      this.set('savingMessage', '');
      this.set('resultMessage', '');
      if (this.get('model') !== null) {
        this.send('rollback', this.get('model'));
      }
    },
    refreshTable: function refreshTable() {
      if (this.get('simtex.refresh_table') === undefined) {
        this.set('simtex.refresh_table', 0);
      } else {
        this.incrementProperty('simtex.refresh_table');
      }
    },
    validateInput: function validateInput(field) {
      var _this8 = this;
      var fieldsInvalid = false;
      if (field) {
        var val = this.get("model.".concat(field)) || this.get("".concat(field));
        if (val === null || val === undefined || val.toString() === '') {
          this.set("".concat(field, "Invalid"), true);
          fieldsInvalid = true;
          if (field === 'bulkdata') {
            this.set('bulkdataInvalidMsg', "This field is required.");
          }
        } else {
          this.set("".concat(field, "Invalid"), false);
          if (field === 'macaddress') {
            fieldsInvalid = this.validateMacaddress();
          } else if (field === 'bulkdata') {
            fieldsInvalid = this.validateBulkData();
          }
        }
      } else {
        this.get('elements').forEach(function (element) {
          var val = _this8.get("model.".concat(element)) || _this8.get("".concat(element));
          if (val === null || val === undefined || val.toString() === '') {
            _this8.set("".concat(element, "Invalid"), true);
            fieldsInvalid = fieldsInvalid || true;
          } else {
            _this8.set("".concat(element, "Invalid"), false);
            if (element === 'macaddress') {
              fieldsInvalid = fieldsInvalid || _this8.validateMacaddress();
            } else if (element === 'bulkdata') {
              fieldsInvalid = fieldsInvalid || _this8.validateBulkData();
            }
          }
        });
      }
      return fieldsInvalid;
    },
    validateMacaddress: function validateMacaddress() {
      var macaddress_invalid = false;
      var val = this.get('model.macaddress');
      this.set('model.macaddress', val.toUpperCase());
      if (!/^[0-9A-F]{12}$/.test(val.toUpperCase())) {
        this.set('macaddressInvalid', true);
        macaddress_invalid = true;
      } else {
        this.set('macaddressInvalid', false);
      }
      return macaddress_invalid;
    },
    loadEndpointModel: function loadEndpointModel() {
      var _this9 = this;
      var endpointmodel = this.endpointmodel_list.filter(function (item) {
        return item.endpointmodelid === _this9.get('model.endpointmodel_id');
      })[0];
      this.set('endpointmodel', endpointmodel);
      this.set('displayImage', 'inline-block');
    },
    validateBulkData: function validateBulkData() {
      var _this10 = this;
      var error_message = "";
      var is_invalid = false;
      if (this.get('bulkdata') != "") {
        var rows = this.get('bulkdata').split(/[\r\n]+/);
        rows.forEach(function (row, index) {
          var row_message = "";
          var endpoint_data = row.split(',');
          if (endpoint_data.length < 2) {
            row_message = row_message + "the row needs to contain MacAddress,Subscription,Label separated by comma.";
          } else {
            if (!/^[0-9A-F]{12}$/.test(endpoint_data[0].toUpperCase())) {
              row_message = row_message + endpoint_data[0] + " is not a valid mac address";
            }
            if (_this10.account_list.find(function (item) {
              return item.value == endpoint_data[1];
            }) === undefined) {
              if (row_message != "") {
                row_message = row_message + ", ";
              }
              row_message = row_message + endpoint_data[1] + " doesn't belong to this account.";
            }
            if (endpoint_data.length >= 3) {
              if (endpoint_data[2].length > 20) {
                if (row_message != "") {
                  row_message = row_message + ", ";
                }
                row_message = row_message + "Device label can't have more than 20 characters.";
              }
            }
          }
          if (row_message != "") {
            error_message = error_message + "Row ".concat(index + 1, ": ").concat(row_message, "<br>");
          }
        });
      } else {
        error_message = "This field is required.";
      }
      if (error_message != "") {
        this.set("bulkdataInvalid", true);
        this.set("bulkdataInvalidMsg", (0, _template.htmlSafe)(error_message));
        is_invalid = true;
      }
      return is_invalid;
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
      },
      toggleMessageModal: function toggleMessageModal() {
        this.toggleProperty('messageModalShowing');
        this.resetModal();
        this.initialiseHandset();
        this.refreshTable();
      },
      inputChangeAction: function inputChangeAction(field) {
        this.validateInput(field);
        if (field === 'endpointmodel_id') {
          this.loadEndpointModel();
        }
      },
      cancellingModal: function cancellingModal() {
        this.toggleProperty('modalCancelling');
        if (!this.promptOnClose) {
          this.toggleProperty('modalShowing');
          this.resetModal();
          this.initialiseHandset();
        }
      },
      cancelModal: function cancelModal() {
        this.toggleProperty('modalCancelling');
        this.toggleProperty('modalShowing');
        this.resetModal();
        this.initialiseHandset();
      },
      enpointmodeltypeChange: function enpointmodeltypeChange() {
        this.validateInput('endpointmodeltypeid');
        this.get_endpointmodel_list();
        this.get_endpointdialplan_list();
      },
      completeModal: function completeModal() {
        var fieldsInvalid = this.validateInput(null);
        if (fieldsInvalid) {
          return;
        }
        this.toggleProperty('modalShowing');
        this.toggleProperty('messageModalShowing');
        this.set('resultMessage', (0, _template.htmlSafe)("<p>Creating handsets. Please wait... <span class=\"spinner spinner-inline\">Loading...</span></p>"));
        var promiseArray = this.processData();
        this.saveEndpoint(promiseArray);
      }
    }
  });
  _exports.default = _default;
});