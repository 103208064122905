define("simtex-myaccount/routes/restricted/subscriptions/subscription", ["exports", "@ember/routing/route", "@ember/service", "ember-data"], function (_exports, _route, _service, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend({
    // queryParams: {
    //     refreshAcct: {
    //         refreshModel: true
    //     }
    // },
    router: (0, _service.inject)(),
    beforeModel: function beforeModel() {
      this.simtex.set_user_nav(true);
    },
    setupController: function setupController(controller, model) {
      var _this = this;
      this._super(controller, model);
      if (this.simtex.is_admin || this.simtex.is_reseller) {
        this.simtex.set('viewing_owner', model.owner || 'USN empty');
        if (model.owner) {
          this.simtex.set('viewing_account', _emberData.default.PromiseObject.create({
            promise: this.store.findRecord('owner', model.owner).then(function (result) {
              return result.smile_data;
            }).then(function (smile_data) {
              return _this.simtex.getSmileProperty(smile_data.Properties.Object.String, 'name');
            })
          }));
        } else {
          this.simtex.set('viewing_account', {
            'content': 'No Smile Acct found'
          });
        }
      }
    },
    model: function model(params) {
      var _this2 = this;
      var account_id = params.account_id;
      if (account_id === 'nosub') {
        return this.store.findRecord('owner', params.owner, {
          reload: true
        });
      } else {
        // Deal with nasty ember-data bug, solution from here:
        // https://stackoverflow.com/questions/63362616/ember-data-creates-duplicate-record-on-model-save-ember-cli-v3-19
        // Caused by handset (endpoint) / subscription (account) mapping additions.
        this.store.peekAll('endpointaccount').filterBy('isNew', true).forEach(function (item) {
          return _this2.store.deleteRecord(item);
        });
        return this.store.findRecord('account', account_id).then(function (acct) {
          //acct.rollbackRelationships();
          return acct;
        });
      }
    },
    actions: {
      save_nested: function save_nested(nested_obj) {
        return nested_obj.then(function (result) {
          return result.save();
        });
      }
    }
  });
  _exports.default = _default;
});