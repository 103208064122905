define("simtex-myaccount/components/handsets/handset-subscription-buckets", ["exports", "@ember/component", "@ember/array/proxy"], function (_exports, _component, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    slush_bucket_context: {
      // Reference data
      // Model name for primary bucket
      ref_model_primary: 'endpoint',
      ref_model_primary_remove_fields: ['configdefinitiongroup_data', 'endpointexpansion_data'
      // 'endpointdialplan',
      // 'zerotouchstate',
      ],

      ref_model_primary_ordering: 'label',
      ref_model_primary_optionval: 'id',
      ref_model_primary_optionlabel: '${label} - ${brand_model}',
      // (${endpointid})
      ref_model_primary_optionlabeldict: {
        'label': 'label',
        'endpointid': 'endpointid',
        'brand_model': 'endpointmodel.formatted_brand_model'
      },
      ref_model_primary_filter_dict: {
        'provisionconfiglist': '0,1,2,3,4,5,6',
        'zerotouchstatelist': '0,1,2,3,4,20,21,22,99'
      },
      // Model name for allocation buckets
      ref_model_allocation: 'account',
      ref_model_allocation_then: function ref_model_allocation_then(result) {
        // return ArrayProxy.create({
        //     content: result
        // }).reduce(function(cur, next) {
        //     return cur.then(() => {
        //         return next;
        //         // return next.hasMany('endpointaccount_data').reload().then((ea_data) => { return next });
        //     });
        // }, Ember.RSVP.resolve()).then(() => {
        //     return result.toArray().filter(acct => acct.get('product_data.is_cloud_pbx'));
        // })

        return result.toArray().filter(function (acct) {
          return acct.get('product_data.is_cloud_pbx');
        });
      },
      ref_model_allocation_remove_fields: ['did_data', 'channellimit_data', 'dialrestriction_data', 'transport_data', 'callcontrol_data', 'advancedforwardcontrol_data', 'endpoint_status_data', 'numberpresentation_data', 'voicemail_data', 'callaccounting_data', "musiconhold_data", "didroute_data"],
      ref_model_allocation_ordering: 'internalextension',
      // Property on the allocation model linking it with the primary model
      ref_model_allocation_primaryrefid: 'endpointaccount_data>endpoint.id',
      ref_model_allocation_primaryrefobj: 'endpointaccount_data>endpoint',
      ref_model_allocation_optionval: 'accountid',
      ref_model_allocation_optionlabel: '${extension} / ${accountid} - ${label} - ${product_label}',
      ref_model_allocation_optionlabeldict: {
        'accountid': 'accountid',
        'extension': 'internalextension',
        'product_label': 'product_data.label',
        'label': 'label'
      },
      // Allow accounts to be shared between endpoints
      allow_shared_allocations_per_primary: true,
      ref_shared_allocation_join: {
        table_name: 'endpointaccount',
        primary_model_valueref: 'endpoint.id',
        allocation_model_valueref: 'account.id'
      },
      hide_allocated_in_unallocated_for_superuser: true,
      // Used in Allocation bucket to describe what the object is allocated to.
      // If null, defaults to primary object label.
      bucket_selected_primary_descriptionref: null,
      strings: {
        primary_bucket_title: 'Handsets and Devices',
        allocated_bucket_title: 'Mapped',
        unallocated_bucket_title: 'Cloud PBX Subscriptions',
        allocate_button_text: 'Map',
        deallocate_button_text: 'Un-Map',
        prompt_primary_noresults: 'No Handsets or Devices are available',
        prompt_allocated_empty_noprimary: 'Select a Handset/Device to view its mapped Subscriptions',
        prompt_allocated_empty_noresults: 'No Subscriptions mapped to this Handset/Device',
        prompt_unallocated_empty: 'No un-mapped Subscriptions',
        saved_allocations_success: 'Subscription mappings updated',
        saved_allocations_failure: 'Error updating Subscription mappings',
        saved_allocated_single_success: 'Subscription ${allocationvalue} mapped to ${label}',
        saved_deallocated_single_success: 'Subscription ${allocationvalue} unmapped from ${label}'
      }
    }
  });
  _exports.default = _default;
});