define("simtex-myaccount/serializers/account", ["exports", "simtex-myaccount/serializers/drf", "@ember/service", "ember-data", "@ember/utils"], function (_exports, _drf, _service, _emberData, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: "accountid",
    simtex: (0, _service.inject)('simtex'),
    attrs: {
      status: {
        embedded: 'always'
      },
      product_data: {
        embedded: 'always'
      },
      did_data: {
        embedded: 'always'
      },
      voicemail_data: {
        embedded: 'always'
      },
      dialrestriction_data: {
        embedded: 'always'
      },
      channellimit_data: {
        embedded: 'always'
      },
      transport_data: {
        embedded: 'always'
      },
      numberpresentation_data: {
        embedded: 'always'
      },
      endpointaccount_data: {
        embedded: 'always'
      },
      callcontrol_data: {
        embedded: 'always'
      },
      callaccounting_data: {
        embedded: 'always'
      },
      advancedforwardcontrol_data: {
        embedded: 'always'
      },
      musiconholdslot_data: {
        embedded: 'always'
      }
    },
    extractMeta: function extractMeta(store, type, payload) {
      var meta = this._super(store, type, payload);
      return this.simtex.extract_meta(meta, type, payload);
    }
  });
  _exports.default = _default;
});