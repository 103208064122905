define("simtex-myaccount/components/callrouting/ringgroup-dialog", ["exports", "@ember/component", "@ember/service", "@ember/object", "ember-data", "simtex-myaccount/mixins/model-saver-common", "@ember/runloop"], function (_exports, _component, _service, _object, _emberData, _modelSaverCommon, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_modelSaverCommon.default, {
    store: (0, _service.inject)(),
    modalMode: 'Add',
    owner: null,
    model: null,
    promptOnClose: true,
    modalCancelling: false,
    labelInvalid: false,
    disableAccountSelector: (0, _object.computed)(function () {
      if (this.modalMode == 'Add' && !this.primary_account) return false;
      return true;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.initialiseRingGroup();
    },
    initialiseRingGroup: function initialiseRingGroup() {
      if (this.modalMode == 'Add') {
        this.set('model', this.store.createRecord('broadcastgroup', {
          delaytime: 0,
          owner: this.owner
        }));
        if (this.primary_account) {
          this.loadGroupAccount(this.primary_account);
        } else {
          this.set('model.account', this.store.createRecord('account'));
        }
      } else {
        this.set('model', this.store.findRecord('broadcastgroup', this.modelId, {
          backgroundReload: false
        }));
      }
    },
    get_subscriptions: (0, _object.computed)(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('account', {
          'owner_filter_term': this.owner,
          'productid__producttype__producttypeid': 4,
          'page_size': 200,
          'remove_fields': ['did_data', 'channellimit_data', 'dialrestriction_data', 'transport_data', 'callcontrol_data', 'advancedforwardcontrol_data', 'endpoint_status_data', 'product_data', 'numberpresentation_data', 'voicemail_data', 'callaccounting_data', "musiconhold_data", "didroute_data"]
        }).then(function (accounts) {
          var account_options = [];
          accounts.forEach(function (account) {
            account_options.push({
              label: "".concat(account.accountid, " (").concat(account.internalextension, ")"),
              value: account.accountid
            });
          });
          return account_options;
        })
      });
    }),
    range_slider_formatter: {
      to: function to(value) {
        return Math.round(value);
      },
      from: function from(value) {
        return Number(value);
      }
    },
    saveChanges: function saveChanges() {
      var _this = this;
      var self = this;
      var model = this.get('model');
      //Add, edit and delete ring groups
      if (model !== null) {
        if (this.modalMode == 'Delete') {
          //Delete all group members
          var members_to_delete = [];
          if (this.get('model.broadcastmember_data').length > 0) {
            this.get('model.broadcastmember_data').forEach(function (member) {
              members_to_delete.push(_emberData.default.PromiseObject.create({
                promise: member.destroyRecord()
              }));
              Promise.all(members_to_delete).then(function () {
                _this.send('save_nested', model, true, true, 'simtex.update_ringgroup_buckets');
              });
            });
          } else {
            this.send('save_nested', model, true, true, 'simtex.update_ringgroup_buckets');
          }
        } else {
          this.send('save_nested', model, true, false, 'simtex.update_ringgroup_buckets');
        }
      }
    },
    rollbackChanges: function rollbackChanges() {
      // Roll back changes to the model.
      if (this.get('model') !== null) {
        this.send('rollback', this.get('model'));
      }
    },
    loadGroupAccount: function loadGroupAccount(accountid) {
      var self = this;
      this.store.query('broadcastgroup', {
        account: accountid
      }).then(function (groups) {
        var max_subgroupindex = 0;
        if (groups.length > 0) max_subgroupindex = Math.max.apply(Math, groups.map(function (group) {
          return group.subgroupindex;
        }));
        self.model.set('subgroupindex', max_subgroupindex + 1);
      });
      this.store.findRecord('account', accountid).then(function (account) {
        self.model.set('account', account);
      });
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('modalShowing');
      },
      cancellingModal: function cancellingModal() {
        this.toggleProperty('modalCancelling');
        if (!this.promptOnClose) {
          this.toggleProperty('modalShowing');
          this.rollbackChanges();
          this.initialiseRingGroup();
        }
      },
      cancelModal: function cancelModal() {
        this.toggleProperty('modalCancelling');
        this.toggleProperty('modalShowing');
        this.rollbackChanges();
        this.initialiseRingGroup();
      },
      setRingTime: function setRingTime(newValue) {
        if (newValue !== undefined) {
          this.model.set('delaytime', newValue);
        }
      },
      groupAccountChangeAction: function groupAccountChangeAction() {
        this.loadGroupAccount(this.model.account.content.accountid);
      },
      validateInput: function validateInput() {
        if (this.model.get('name') == null || this.model.get('name') == '') {
          this.set('labelInvalid', true);
        } else {
          this.set('labelInvalid', false);
        }
      },
      completeModal: function completeModal() {
        var self = this;
        if (self.labelInvalid) return;
        (0, _runloop.later)(function () {
          self.saveChanges();
          self.toggleProperty('modalShowing');
          self.initialiseRingGroup();
        }, 500);
      }
    }
  });
  _exports.default = _default;
});