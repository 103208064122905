define("simtex-myaccount/components/subscriptions/subscription-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object", "@ember/template", "@ember/service"], function (_exports, _component, _tableCommon, _object, _template, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'account',
    sort: 'accountid',
    alwaysFetch: false,
    simtex: (0, _service.inject)('simtex'),
    // Speed up API response by excluding these fields from call to /account
    remove_fields: [
    //'did_data',
    'channellimit_data', 'dialrestriction_data', 'transport_data', 'endpoint_status_data', 'callaccounting_data' // 'endpointaccount_data',
    ],
    init: function init() {
      this._super.apply(this, arguments);
      if (this.simtex.get('get_claims_account') !== null) {
        this.set('alwaysFetch', true);
      }
      this.send('setPage', 1, this.get('alwaysFetch'));
    },
    columns: (0, _object.computed)(function () {
      var self = this;
      var cols = [{
        label: 'Actions',
        width: '150px',
        sortable: false,
        cellComponent: 'subscriptions/subscription-table-actions'
      }, {
        label: 'Account',
        valuePath: 'accountid',
        sortable: true,
        width: '150px'
      }, {
        label: 'Subscription (USN)',
        valuePath: 'smilesubscriptionid',
        sortable: true,
        width: '150px'
      }, {
        label: 'CLID Name / Label',
        sortable: true,
        valuePath: 'label',
        valueEditable: true,
        width: '200px',
        cellComponent: 'light-table-cell',
        cellComponentClassNames: 'simtex-light-table-cell-6'
      }, {
        label: 'Status',
        valuePath: 'status',
        sortable: true,
        width: '150px',
        format: function format(value) {
          switch (value) {
            case 'C':
              return 'Current';
            case 'S':
              return 'Suspended';
            case 'T':
              return 'Terminated';
            default:
              return '(empty)';
          }
        }
      }, {
        label: 'Product Type',
        valuePath: 'productid.label',
        sortable: true,
        format: function format(value) {
          var product_label = this.row.get('product_data.label');
          var product_type_label = this.row.get('product_data.producttype.label');
          return (0, _template.htmlSafe)("".concat(product_label, "<br /><small>").concat(product_type_label, "</small>"));
        }
      }, {
        label: 'Service Details',
        sortable: false,
        cellComponent: 'subscriptions/subscription-service-details'
      }, {
        label: 'Voicemail',
        sortable: true,
        format: function format() {
          var property_html = '';
          // Voicemail not available for Fax
          if (this.row.get('product_data.producttype.producttypeid') !== 1) {
            if (this.row.get('voicemail_data.vmactive')) {
              if (this.row.get('voicemail_data.vmkeepserver')) property_html = property_html + '<clr-icon shape="phone-handset"></clr-icon> Playback on handset<br /><br />';
              if (this.row.get('voicemail_data.vmsendemail')) {
                if (this.row.get('voicemail_data.vmemail')) {
                  property_html = property_html + '<clr-icon shape="envelope"></clr-icon> Send to ' + this.row.get('voicemail_data.vmemail') + '<br /><br />';
                } else {
                  property_html = property_html + '<clr-icon shape="envelope"></clr-icon>' + '<span style="color: red;"> Send Email enabled but no Email Address defined</span>';
                }
              }
            } else {
              // VoiceMail not active
              property_html = '<clr-icon shape="ban"></clr-icon> Disabled';
            }
          } else {
            // VoiceMail not active
            property_html = '<clr-icon shape="ban"></clr-icon> N/A';
          }
          return (0, _template.htmlSafe)(property_html);
        }
      }, {
        label: 'Sign Up Date',
        valuePath: 'signupdate',
        format: function format(value) {
          var pattern = /(.*?) (\(.*?\))/;
          var ret = value ? value.toString().match(pattern) : '(empty)';
          switch (ret.length) {
            case 2:
              ret.shift(); // Remove first element of the array (full match)
              break;
            case 3:
              ret.shift(); // Remove first element of the array (full match)
              ret[1] = '<small>' + ret[1] + '</small>'; // Make timezone text smaller
              break;
            default:
              ret = [ret];
              break;
          }
          return (0, _template.htmlSafe)(ret.join('<br />'));
        }
      }, {
        label: 'Migrated',
        valuePath: 'migrated',
        sortable: true
      }];

      // Only show the Sign Up Date or Migration columns for Admin
      //if (!this.simtex.is_admin) {
      cols.pop();
      cols.pop();
      //}

      return cols;
    }),
    actions: {
      viewSubscription: function viewSubscription(row) {
        this.router.transitionTo('restricted.subscriptions.subscription', row.content.owner, row.content.accountid);
      },
      onRowClick: function onRowClick(row) {
        this.router.transitionTo('restricted.subscriptions.subscription', row.content.owner, row.content.accountid);
      }
    }
  });
  _exports.default = _default;
});