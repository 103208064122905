define("simtex-myaccount/components/handsets/handset-table", ["exports", "@ember/component", "simtex-myaccount/mixins/table-common", "@ember/object"], function (_exports, _component, _tableCommon, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_tableCommon.default, {
    modelName: 'endpoint',
    sort: 'endpointaccount__account_internalextension',
    filterList: ['provisionconfiglist', 'zerotouchstatelist'],
    provisionconfiglist: '0,1,2,3,4,5,6',
    zerotouchstatelist: '0,1,2,3,4,20,21,22,99',
    page_size: 10,
    remove_fields: ['configdefinitiongroup_data', 'endpointexpansion_data', 'callaccounting_data', 'baselinekey_data' // removed from endpointaccount_data
    ],
    init: function init() {
      this._super.apply(this, arguments);
      this.send('setPage', 1);
    },
    columns: (0, _object.computed)(function () {
      var simtex = this.simtex;
      return [{
        label: 'Actions',
        width: '150px',
        sortable: false,
        cellComponent: 'handsets/handset-table-actions'
      }, {
        label: 'Friendly Name',
        sortable: true,
        valuePath: 'label',
        valueEditable: true,
        placeholder: 'first_endpointaccount_label',
        subtext: 'Defaults to first Subscription Label if empty.',
        width: '250px',
        cellComponent: 'light-table-cell',
        cellComponentClassNames: 'simtex-light-table-cell-8'
      }, {
        label: 'Subscription(s)',
        sortable: true,
        // valuePath: 'endpointaccount.account.internalextension',
        valuePath: 'endpointaccount.account_internalextension',
        cellComponent: 'accounts/account-light-table-cell'
      }, {
        label: 'Provisioning Status',
        sortable: true,
        valuePath: 'provisionconfig_label',
        format: function format() {
          return simtex.getModelProperty(this.row, 'provisionconfig_label');
        }
      }, {
        label: 'Zone',
        sortable: true,
        valuePath: 'endpointdialplan.areaname',
        format: function format() {
          return simtex.getModelProperty(this.row, 'endpointdialplan.areaname');
        }
      }, {
        label: 'Brand',
        sortable: true,
        valuePath: 'endpointmodel.brand',
        format: function format() {
          return simtex.getModelProperty(this.row, 'endpointmodel.brand');
        }
      }, {
        label: 'Model',
        sortable: true,
        valuePath: 'endpointmodel.model',
        format: function format() {
          var model = simtex.getModelProperty(this.row, 'endpointmodel.model', '');
          if (model) return model.toUpperCase();else return '(empty)';
        }
      }, {
        label: 'MAC Address',
        sortable: true,
        valuePath: 'macaddress',
        format: function format() {
          return simtex.getModelProperty(this.row, 'formatted_macaddress');
        }
      }];
    })
  });
  _exports.default = _default;
});